import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../utils/storage.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(
    private storage: StorageService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.storage.getItem('token');
    const apiEicon = request.url.indexOf(environment.api) > -1 ? true : false;
    const newRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.storage.getItem('token')}`
      }
    });
    return next.handle((token && apiEicon) ? newRequest : request);
  }

}
