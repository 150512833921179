import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { FluxoDadosPessoaisService } from './fluxo-dados-pessoais.service';
import { CustomValidators } from 'src/app/core/components/app/form-validator/form-validator.service';
import { FluxoAutenticacaoService } from '../fluxo-autenticacao.service';
import { StorageService } from 'src/app/core/utils/storage.service';
import { NotifierService } from 'src/app/core/components/app/notifier/notifier.service';
import { WizardComponent } from 'src/app/core/components/app/wizard/wizard.component';

@Component({
  selector: 'app-fluxo-dados-pessoais',
  templateUrl: './fluxo-dados-pessoais.component.html',
  styleUrls: ['./fluxo-dados-pessoais.component.scss']
})
export class FluxoDadosPessoaisComponent implements OnInit {

  @Input() elementRef: WizardComponent;

  form: FormGroup;
  cpfNaoLocalizado: boolean;
  usuario: any;
  idCliente = this.storage.getItem('idCliente');

  maxDateNascimento = new Date();

  constructor(
    private fb: FormBuilder,
    private storage: StorageService,
    private notifier: NotifierService,
    private dadosPessoaisService: FluxoDadosPessoaisService,
    private fluxoAutenticacaoService: FluxoAutenticacaoService
  ) { }

  ngOnInit(): void {
    this.fluxoAutenticacaoService.emitChangeStep(1);
    this.buildForm();
  }

  private buildForm(): void {
    this.form = this.fb.group({
      cpf: ['', [Validators.required, CustomValidators.cpfValidator]],
    }, { validator: this.checkEmailsEqual });
  }

  public changeCpf(): void {
    if (this.form.value.cpf.length === 11) {
      const errorCpf = CustomValidators.cpfValidator(this.form.get('cpf'));
      if (!errorCpf) {
        this.getUsarioCpf();
      }
    }
  }

  private getUsarioCpf(): void {
    this.dadosPessoaisService.getUsarioCpf(this.form.value.cpf).subscribe(data => {
      this.usuario = data.conteudo ? data.conteudo : null;
      this.cpfNaoLocalizado = this.usuario ? false : true;
      if (!this.usuario) {
        this.setForm();
      }
    });
  }

  private setForm(): void {
    this.form.addControl('nome', this.fb.control('', [Validators.required, CustomValidators.nameValidator]));
    this.form.addControl('nomeMae', this.fb.control(null, [Validators.required]));
    this.form.addControl('dataNascimento', this.fb.control(null, [Validators.required]));
    this.form.addControl('rg', this.fb.control(null, [Validators.required]));
    this.form.addControl('crc', this.fb.control(null));
    this.form.addControl('celular', this.fb.control(null, [Validators.required]));
    this.form.addControl('email', this.fb.control('', [Validators.required, CustomValidators.emailValidator]));
    this.form.addControl('confirmarEmail', this.fb.control('', [Validators.required, CustomValidators.emailValidator]));
  }

  private checkEmailsEqual(control: AbstractControl) {
    if (control.get('email') && control.get('confirmarEmail')) {
      if (control.get('email').value !== control.get('confirmarEmail').value) {
        control.get('confirmarEmail').setErrors({ emailNotEqual: true });
      }
    }
  }

  public submitForm(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.verificaSeAdicionaUsuario();
    }
  }

  private verificaSeAdicionaUsuario(): void {
    if (this.cpfNaoLocalizado) {
      this.addUsuario();
    } else {
      this.proximoPasso();
    }
  }

  private addUsuario(): void {
    this.storage.removeItem('token');
    const celular = this.form.value.celular;
    const input = {
      crc: this.form.value.crc,
      documento: this.form.value.cpf,
      documentoTipo: 0,
      email: { email: this.form.value.email },
      idCliente: this.idCliente,
      nome: this.form.value.nome,
      nomeMae: this.form.value.nomeMae,
      dataNascimento: this.form.value.dataNascimento.toISOString(),
      rg: this.form.value.rg,
      telefone: { codigoArea: celular.substring(0, 2), telefone: celular.substring(2), telefoneTipo: 4 }
    };
    this.dadosPessoaisService.adicionarUsuario(input).subscribe(() => {
      this.notifier.notify(
        'success',
        'Cadastro de Usuário',
        'Para concluir o seu cadastro informe a senha que foi enviada para o e-mail cadastrado.'
      );
      this.proximoPasso();
    });
  }

  private proximoPasso(): void {
    this.fluxoAutenticacaoService.setData('form', this.form.value);
    this.fluxoAutenticacaoService.setData('usuario', this.usuario);
    this.fluxoAutenticacaoService.setData('cpfNaoLocalizado', this.cpfNaoLocalizado);
    this.fluxoAutenticacaoService.emitChangeStep(2);
    this.elementRef.nextStep();
  }

}
