import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/core/utils/storage.service';

@Injectable({
  providedIn: 'root'
})

export class VisualizarGuiaService {

  private api = environment.api;

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  buscarGuia(nrGuia: string): Observable<any> {
    const idCliente = this.storage.getItem('idCliente');
    return this.http.get(`${this.api}/service-declaracao/api/baixa/cliente/${idCliente}/numero/${nrGuia}`);
  }

}

