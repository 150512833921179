import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portal-servidor',
  templateUrl: './portal-servidor.component.html',
  styleUrls: ['./portal-servidor.component.scss']
})
export class PortalServidorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
