import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { VisualizarGuiaService } from './visualizar-guia.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-visualizar-guia',
  templateUrl: './visualizar-guia.component.html',
  styleUrls: ['./visualizar-guia.component.scss']
})
export class VisualizarGuiaComponent implements OnInit {

  @Output() retornarGuia = new EventEmitter();

  form: FormGroup;
  guia: any;

  constructor(
    private fb: FormBuilder,
    private visualizarGuiaService: VisualizarGuiaService,
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm(): void {
    this.form = this.fb.group({
      nrGuia: [null, [Validators.required]],
    });
  }

  public submitForm(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.getGuia();
    }
  }

  private getGuia(): void {
    this.visualizarGuiaService.buscarGuia(this.form.value.nrGuia).subscribe(data => {
      this.guia = data.conteudo;
      this.retornarGuia.emit(this.guia);
    });
  }

}
