import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AutenticarService {

  private api = environment.api;

  constructor(
    private http: HttpClient
  ) { }

  validarCelular(idUsuario: string): Observable<any> {
    return this.http.get(`${this.api}/service-empresa/api/login/validar-telefone/${idUsuario}`);
  }

  enviarSms(data: any): Observable<any> {
    return this.http.post(`${this.api}/service-token/api/token/enviar-sms`, data);
  }

  validarSms(data: any): Observable<any> {
    return this.http.post(`${this.api}/service-token/api/token/validar`, data);
  }

  public getUsuarioPorId(id: string, token?: string): Observable<any> {
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', `Bearer ${token}`);
      return this.http.get(`${this.api}/service-empresa/api/usuario/${id}`,  { headers });
    }

    return this.http.get(`${this.api}/service-empresa/api/usuario/${id}`);
  }

}
