import { Directive, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { SortableColumnService } from './sortable-column.service';

@Directive({
  selector: '[appSortableTable]'
})

export class SortableTableDirective implements OnInit, OnDestroy {

  constructor(private sortableColumnService: SortableColumnService) { }

  @Input() list: any[];

  private columnSortedSubscription: Subscription;

  ngOnInit() {
    this.sortTable();
  }

  private sortTable(): void {
    this.columnSortedSubscription = this.sortableColumnService.columnSorted$.subscribe(event => {
      const f = event.sortColumn.split('.');
      this.list.sort((a, b) => {
        if (event.sortDirection === 'asc') {
          switch (f.length) {
            case 1: // example: order field asc - client
              return a[f[0]] < b[f[0]] ? -1 : a[f[0]] > b[f[0]] ? 1 : 0;
            case 2: // example: order field asc - client.companyName
              return a[f[0]][f[1]] < b[f[0]][f[1]] ? -1 : a[f[0]][f[1]] > b[f[0]][f[1]] ? 1 : 0;
            case 3: // example: order field asc - client.address.city
              return a[f[0]][f[1]][f[2]] < b[f[0]][f[1]][f[2]] ? -1 : a[f[0]][f[1]][f[2]] > b[f[0]][f[1]][f[2]] ? 1 : 0;
          }
        }
        if (event.sortDirection === 'desc') {
          switch (f.length) {
            case 1: // example: order field desc - client
              return a[f[0]] > b[f[0]] ? -1 : a[f[0]] < b[f[0]] ? 1 : 0;
            case 2: // example: order field desc - client.companyName
              return a[f[0]][f[1]] > b[f[0]][f[1]] ? -1 : a[f[0]][f[1]] < b[f[0]][f[1]] ? 1 : 0;
            case 3: // example: order field desc - client.address.city
              return a[f[0]][f[1]][f[2]] > b[f[0]][f[1]][f[2]] ? -1 : a[f[0]][f[1]][f[2]] < b[f[0]][f[1]][f[2]] ? 1 : 0;
          }
        }
      });
    });
  }

  ngOnDestroy() {
    this.columnSortedSubscription.unsubscribe();
  }

}
