// angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';

// ngx bootstrap
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale);

// external components
import { NgHttpLoaderModule } from 'ng-http-loader';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { TableModule } from 'ngx-easy-table'
import { ngfModule } from "angular-file";

// app components
import { PortalServidorComponent } from './layouts/portal-servidor/portal-servidor.component';
import { PortalEmpresaCidadaoComponent } from './layouts/portal-empresa-cidadao/portal-empresa-cidadao.component';
import { FormValidatorComponent } from './components/app/form-validator/form-validator.component';
import { ScrollToFirstInvalidDirective } from './components/app/scroll-to-first-invalid/scroll-to-first-invalid.directive';
import { WizardComponent } from './components/app/wizard/wizard.component';
import { WizardStepComponent } from './components/app/wizard-step/wizard-step.component';
import { CommonModalComponent } from './components/app/common-modal/common-modal.component';
import { DialogConfirmComponent } from './components/app/dialog-confirm/dialog-confirm.component';
import { DialogOkComponent } from './components/app/dialog-ok/dialog-ok.component';
import { HeaderComponent } from './components/app/header/header.component';
import { NotifierComponent } from './components/app/notifier/notifier.component';
import { FooterComponent } from './components/app/footer/footer.component';
import { PageTitleComponent } from './components/app/page-title/page-title.component';
import { SubHeaderComponent } from './components/app/sub-header/sub-header.component';
import { SortableTableDirective } from './components/app/sortable-column/sortable-column.directive';
import { SortableColumnComponent } from './components/app/sortable-column/sortable-column.component';

// giss components
import { AutocompleteEmpresaComponent } from './components/giss/autocomplete-empresa/autocomplete-empresa.component';
import { BuscarEmpresaComponent } from './components/giss/buscar-empresa/buscar-empresa.component';
import { BannerComponent } from './components/giss/banner/banner.component';
import { VisualizarNotaComponent } from './components/giss/visualizar-nota/visualizar-nota.component';
import { VisualizarGuiaComponent } from './components/giss/visualizar-guia/visualizar-guia.component';
import { FluxoDadosPessoaisComponent } from './components/giss/fluxo-autenticacao/fluxo-dados-pessoais/fluxo-dados-pessoais.component';
import { FluxoAutenticarComponent } from './components/giss/fluxo-autenticacao/fluxo-autenticar/fluxo-autenticar.component';

@NgModule({
  declarations: [
    PortalServidorComponent,
    PortalEmpresaCidadaoComponent,
    FormValidatorComponent,
    ScrollToFirstInvalidDirective,
    WizardComponent,
    WizardStepComponent,
    CommonModalComponent,
    DialogConfirmComponent,
    DialogOkComponent,
    HeaderComponent,
    NotifierComponent,
    FooterComponent,
    PageTitleComponent,
    SubHeaderComponent,
    SortableTableDirective,
    SortableColumnComponent,
    AutocompleteEmpresaComponent,
    BuscarEmpresaComponent,
    BannerComponent,
    VisualizarNotaComponent,
    VisualizarGuiaComponent,
    FluxoDadosPessoaisComponent,
    FluxoAutenticarComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    CarouselModule.forRoot(),
    TabsModule.forRoot(),
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    InlineSVGModule.forRoot({ baseUrl: environment.base }),
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    NgSelectModule,
    TableModule,
    ngfModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    ModalModule,
    TooltipModule,
    PopoverModule,
    CarouselModule,
    TabsModule,
    CollapseModule,
    BsDatepickerModule,
    NgHttpLoaderModule,
    InlineSVGModule,
    NgxMaskModule,
    CurrencyMaskModule,
    NgSelectModule,
    TableModule,
    ngfModule,
    PortalServidorComponent,
    PortalEmpresaCidadaoComponent,
    FooterComponent,
    PageTitleComponent,
    SubHeaderComponent,
    SortableTableDirective,
    SortableColumnComponent,
    AutocompleteEmpresaComponent,
    BuscarEmpresaComponent,
    BannerComponent,
    VisualizarNotaComponent,
    VisualizarGuiaComponent,
    FormValidatorComponent,
    ScrollToFirstInvalidDirective,
    WizardComponent,
    WizardStepComponent,
    CommonModalComponent,
    DialogConfirmComponent,
    DialogOkComponent,
    HeaderComponent,
    NotifierComponent,
    FluxoDadosPessoaisComponent,
    FluxoAutenticarComponent,
  ]
})

export class CoreModule {
  constructor(private localeService: BsLocaleService) {
    ptBrLocale.invalidDate = '';
    defineLocale('locale', ptBrLocale);
    this.localeService.use('locale');
  }
}
