import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FluxoAutenticacaoService {

  private changeStepSource = new BehaviorSubject<number>(1);
  changeStep = this.changeStepSource.asObservable();

  private data = {};

  constructor() { }

  public setData(option: string, value: any) {
    this.data[option] = value;
  }

  public getData() {
    return this.data;
  }

  public emitChangeStep(stepNumber: number) {
    this.changeStepSource.next(stepNumber);
  }

}
