import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/core/utils/storage.service';

@Injectable({
  providedIn: 'root'
})

export class BannerService {

  private api = environment.api;

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  getIndicadores(): Observable<any> {
    const idCliente = this.storage.getItem('idCliente');
    if (!idCliente) {
      setTimeout(() => {
        this.storage.getItem('idCliente');
        return this.http.get(`${this.api}/service-cliente/api/cliente/${idCliente}/estatisticas`);
      }, 1500);
    }
    if (idCliente) {
      return this.http.get(`${this.api}/service-cliente/api/cliente/${idCliente}/estatisticas`);
    }
  }

}
