import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portal-empresa-cidadao',
  templateUrl: './portal-empresa-cidadao.component.html',
  styleUrls: ['./portal-empresa-cidadao.component.scss']
})
export class PortalEmpresaCidadaoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
