import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { DialogOkService } from './dialog-ok.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-dialog-ok',
  templateUrl: './dialog-ok.component.html',
  styleUrls: ['./dialog-ok.component.scss']
})
export class DialogOkComponent implements OnInit {

  @ViewChild('modalDialog', { static: false }) modalDialog: ElementRef;
  modalRef: BsModalRef;
  dialog: any;

  constructor(
    private modalService: BsModalService,
    private dialogOkService: DialogOkService,
  ) { }

  ngOnInit(): void {
    this.observableOpenDialog();
  }

  observableOpenDialog(): void {
    this.dialogOkService.getDialogData().subscribe(dialog => {
      this.dialog = dialog;
      this.modalRef = this.modalService.show(
        this.modalDialog, {
        keyboard: false, backdrop: 'static', class: 'modal-dialog-centered'
      });
    });
  }

}
