<form autocomplete="off" novalidate [formGroup]="form" (ngSubmit)="submitForm()" appScrollToFirstInvalid>
  <fieldset>
    <legend>Pesquisar Guia</legend>
    <div class="form-group col-md-3">
      <div class="material-textfield">
        <input placeholder="Informar Nº Guia" formControlName="nrGuia" id="search" name="search" mask="0*" type="text">
        <label></label>
        <div aria-label="buscar" class="input-icon" (click)="submitForm()" [inlineSVG]="'/assets/icons/search.svg'">
        </div>
      </div>
    </div>
  </fieldset>
</form>

<fieldset *ngIf="guia">
  <legend>Dados da Empresa</legend>
  <div class="form-row">
    <div class="col-md-3">
      <div class="material-textfield">
        <input readonly [value]="guia.documentoEmpresa | mask: 'CPF_CNPJ'" id="documento" name="documento" type="text">
        <label for="documento">CNPJ/CPF</label>
      </div>
    </div>
    <div class="col-md-4">
      <div class="material-textfield">
        <input readonly [value]="guia.razaoSocialEmpresa" id="razaoSocial" name="razaoSocial" type="text">
        <label for="razaoSocial">Razão Social</label>
      </div>
    </div>
    <div class="col-md-3">
      <div class="material-textfield">
        <input readonly [value]="guia.imEmpresa" id="inscricaoMunicipal" name="inscricaoMunicipal" type="text">
        <label for="inscricaoMunicipal">Inscrição Municipal</label>
      </div>
    </div>
  </div>
  <div class="form-row mt-3">
    <div class="col-md-12">
      <div class="material-textfield">
        <input readonly class="text-capitalize" [value]="guia.enderecoEmpresa | lowercase" id="enderecoEmpresa"
          name="enderecoEmpresa" type="text">
        <label for="enderecoEmpresa">Endereço</label>
      </div>
    </div>
  </div>
</fieldset>

<fieldset *ngIf="guia">
  <legend>Dados da Guia</legend>
  <div class="form-row">
    <div class="col-md-3">
      <div class="material-textfield">
        <input readonly [value]="guia.numeroDocumento" id="numeroDocumento" name="numeroDocumento" type="text">
        <label for="numeroDocumento">Nº Guia</label>
      </div>
    </div>
    <div class="col-md-3">
      <div class="material-textfield">
        <input readonly [value]="guia.dataGeracaoGuia" id="dataGeracaoGuia" name="dataGeracaoGuia" type="text">
        <label for="dataGeracaoGuia">Data Emissão</label>
      </div>
    </div>
    <div class="col-md-3">
      <div class="material-textfield">
        <input readonly [value]="guia.cobrancaOrigem" id="cobrancaOrigem" name="cobrancaOrigem" type="text">
        <label for="cobrancaOrigem">Cobrança</label>
      </div>
    </div>
    <div class="col-md-3">
      <div class="material-textfield">
        <input readonly [value]="guia.vencimento | date: 'dd/MM/yyyy'" id="vencimento" name="vencimento" type="text">
        <label for="vencimento">Vencimento</label>
      </div>
    </div>
  </div>
  <div class="form-row mt-3">
    <div class="col-md-3">
      <div class="material-textfield">
        <input readonly [value]="guia.valor | currency: 'BRL'" id="valor" name="valor" type="text">
        <label for="valor">ISSQN</label>
      </div>
    </div>
    <div class="col-md-3">
      <div class="material-textfield">
        <input readonly [value]="guia.multa | currency: 'BRL'" id="multa" name="multa" type="text">
        <label for="multa">Multa</label>
      </div>
    </div>
    <div class="col-md-3">
      <div class="material-textfield">
        <input readonly [value]="guia.juros | currency: 'BRL'" id="juros" name="juros" type="text">
        <label for="juros">Juros</label>
      </div>
    </div>
    <div class="col-md-3">
      <div class="material-textfield">
        <input readonly [value]="guia.taxaExpediente | currency: 'BRL'" id="taxaExpediente" name="taxaExpediente"
          type="text">
        <label for="taxaExpediente">Taxa</label>
      </div>
    </div>
  </div>
  <div class="form-row mt-3">
    <div class="col-md-3">
      <div class="material-textfield">
        <input readonly [value]="guia.valorTotal | currency: 'BRL'" id="valorTotal" name="valorTotal" type="text">
        <label for="valorTotal">Total ISSQN</label>
      </div>
    </div>
    <div class="col-md-3">
      <div class="material-textfield">
        <input readonly [value]="guia.situacao" id="situacao" name="situacao" type="text">
        <label for="situacao">Situação</label>
      </div>
    </div>
  </div>
</fieldset>