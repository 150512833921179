import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NotifierService } from './notifier.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.scss']
})
export class NotifierComponent implements OnInit {

  notification: any;
  modalRef: BsModalRef;
  @ViewChild('modalNotification', { static: false }) modalNotification: ElementRef;

  constructor(
    private notifierService: NotifierService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.notify();
  }

  private notify() {
    this.notifierService.notifier.subscribe((notification: any) => {
      this.notification = notification;
      this.modalRef = this.modalService.show(
        this.modalNotification, {
        keyboard: false, backdrop: 'static', class: 'modal-dialog-centered modal-md'
      });
    });
  }

}
