import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../utils/storage.service';

import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private api = environment.api;
  private appId = environment.appId;
  private authenticateSource = new BehaviorSubject<boolean>(this.isAuthenticated());
  authenticated = this.authenticateSource.asObservable();

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  getToken(username: string, password: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/x-www-form-urlencoded');
    headers = headers.set('APP_ID', this.appId);
    headers = headers.set('PARAM_USER', 'CodCliente');
    const body = `grant_type=password&username=${username}&password=${password}&tipoLogin=0`;
    return this.http.post(`${this.api}/service-empresa/api/login/token`, body, { headers });
  }

  getPermission(token: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);
    return this.http.get(`${this.api}/service-empresa/api/login/permissao`, { headers });
  }

  public isAuthenticated(): boolean {
    const user = this.storage.getItem('token');
    return !!user;
  }

  public emitAuth(state: boolean): void {
    this.authenticateSource.next(state);
  }

  public register(token: string): void {
    this.storage.setItem('token', token);
    this.emitAuth(true);
  }

  public getIdUsuario(): any {
    const token = this.storage.getItem('token');
    const payload = <any> jwtDecode(token);

    return payload.CODIGO_USUARIO;
  }

  public getLogin(): any {
    const token = this.storage.getItem('token');
    const payload = <any> jwtDecode(token);

    return payload.LOGIN;
  }

}
