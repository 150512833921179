import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class FluxoDadosPessoaisService {

  private api = environment.api;

  constructor(
    private http: HttpClient
  ) { }

  getUsarioCpf(cpf: string): Observable<any> {
    return this.http.get(`${this.api}/service-empresa/api/login/usuario/${cpf}`);
  }

  adicionarUsuario(data: any): Observable<any> {
    return this.http.post(`${this.api}/service-empresa/api/login/usuario`, data);
  }

}
