import { Component, AfterContentInit, ContentChildren, QueryList } from '@angular/core';
import { WizardStepComponent } from '../wizard-step/wizard-step.component';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements AfterContentInit {

  @ContentChildren(WizardStepComponent)
  wizardSteps: QueryList<WizardStepComponent>;
  stepActive: WizardStepComponent;

  constructor() { }

  ngAfterContentInit() {
    this.initSteps();
  }

  initSteps() {
    this.wizardSteps.forEach((step, i) => {
      step.completed = false;
      step.active = i === 0 ? true : false;
    });
    this.setActiveStep();
  }

  setActiveStep() {
    this.stepActive = this.wizardSteps.find(s => s.active);
  }

  nextStep() {
    this.stepActive.completed = true;
    const newStepActive = this.stepActive.number + 1;
    this.wizardSteps.forEach(step => {
      step.active = step.number === newStepActive ? true : false;
    });
    this.stepActive.stepFinish.emit();
    this.setActiveStep();
    window.scrollTo(0, 0);
  }

  previousStep() {
    const newStepActive = this.stepActive.number - 1;
    this.wizardSteps.forEach(step => {
      step.active = step.number === newStepActive ? true : false;
    });
    this.setActiveStep();
    window.scrollTo(0, 0);
  }

  changeStep(step: WizardStepComponent) {
    if (step.completed) {
      const newStepActive = step.number;
      this.wizardSteps.forEach(s => {
        s.active = s.number === newStepActive ? true : false;
      });
      this.setActiveStep();
    }
  }

  completeStep() {
    this.stepActive.stepFinish.emit();
  }

  showStep(step: number, show: boolean) {
    const wizardStep = this.wizardSteps.find(s => s.number === step);
    wizardStep.hiddenStep = !show;
  }

}
