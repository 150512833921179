<div class="sub-header row">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h1 [style.color]="color" [tooltip]="title" [delay]="1000">{{ title }}</h1>
        <hr>
        <img class="background" src="../../../../assets/icons/header-gissonline.svg">
      </div>
    </div>
  </div>
</div>