<div class="icons" *ngIf="nota">
  <div aria-label="download pdf" class="icon" (click)="downloadPdf(nota.nota)" tooltip="Download PDF"
    [inlineSVG]="'/assets/icons/pdf.svg'">
  </div>
  <div aria-label="download xml" class="icon ml-2" (click)="downloadXml(nota.nota)" tooltip="Download XML"
    [inlineSVG]="'/assets/icons/xml.svg'">
  </div>
</div>

<fieldset *ngIf="nota.historico.length > 0">
  <legend>Histórico da Nota</legend>
  <div class="form-row" *ngFor="let hist of nota.historico">
    <div class="form-group col-md-3">
      <span class="key">{{ hist.tipo === 0 ? 'Situação' : 'Condição' }}</span>
      <span class="value">{{ hist.status }}</span>
    </div>
    <div class="form-group col-md-3">
      <span class="key">Data</span>
      <span class="value">{{ hist.data }}</span>
    </div>
    <div class="form-group col-md-6">
      <span class="key">Motivo</span>
      <span class="value">{{ hist.motivo }}</span>
    </div>
  </div>
</fieldset>

<fieldset *ngIf="nota">
  <legend>Dados da {{ nota.origem }}</legend>
  <div class="form-row">
    <div class="form-group" [ngClass]="nota.nota.numeroDeclarado ? 'col-md-2' : 'col-md-3'">
      <div class="material-textfield">
        <input disabled id="numeroNfse" name="numeroNfse" type="text" [value]="nota.nota.numero">
        <label>Nº {{ nota.origem }}</label>
      </div>
    </div>
    <div class="form-group" *ngIf="nota.nota.numeroDeclarado"
      [ngClass]="nota.nota.numeroDeclarado ? 'col-md-2' : 'col-md-3'">
      <div class="material-textfield">
        <input disabled id="numeroDocumento" name="numeroDocumento" type="text" [value]="nota.nota.numeroDeclarado">
        <label>Nº Documento</label>
      </div>
    </div>
    <div class="form-group" [ngClass]="nota.nota.numeroDeclarado ? 'col-md-2' : 'col-md-3'">
      <div class="material-textfield">
        <input disabled placeholder="Série" id="serieNfse" name="serieNfse" type="text"
          [value]="nota.nota.numeroDeclarado ? nota.nota.serieDeclarada ? nota.nota.serieDeclarada : '' : nota.nota.serie">
        <label>Série</label>
      </div>
    </div>
    <div class="form-group col-md-3">
      <div class="material-textfield">
        <input disabled id="codigoVerificacao" name="codigoVerificacao" type="text"
          [value]="nota.nota.codigoVerificacao">
        <label>Código de Verificação</label>
      </div>
    </div>
    <div class="form-group col-md-3">
      <div class="material-textfield">
        <input disabled id="dataEmissao" name="dataEmissao" type="text"
          [value]="nota.nota.dataEmissao | date: 'dd/MM/yyyy HH:mm'">
        <label>Data de Emissão</label>
      </div>
    </div>
  </div>
</fieldset>

<fieldset
  *ngIf="(nota.nota.notaTipo === 2 || nota.nota.notaTipo === 3 || nota.nota.notaTipo === 8) || (nota.nota.notaTipo === 1 && modalidade === 2)">
  <legend>Dados do Prestador de Serviço</legend>
  <div class="row">
    <div class="form-group col-md-3">
      <span class="key">CNPJ / CPF</span>
      <span class="value">{{ nota.prestador.documento | mask: 'CPF_CNPJ' }}</span>
    </div>
    <div class="form-group col-md-3">
      <span class="key">Inscrição Municipal</span>
      <span class="value">{{ nota.prestador.inscricaoMunicipal }}</span>
    </div>
    <div class="form-group col-md-3">
      <span class="key">Razão Social</span>
      <span class="value text-capitalize">{{ nota.prestador.razaoSocial | lowercase }}</span>
    </div>
    <div class="form-group col-md-3">
      <span class="key">E-Mail</span>
      <span class="value">{{ nota.prestador.email ? (nota.prestador.email | lowercase) : '-' }}</span>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-3">
      <span class="key">Endereço</span>
      <span
        class="value text-capitalize">{{ nota.prestador.endereco ? nota.prestador.endereco : nota.prestador.enderecoExterior }}</span>
    </div>
    <div class="form-group col-md-3">
      <span class="key">Número</span>
      <span class="value">{{ nota.prestador.numero | lowercase }}</span>
    </div>
    <div class="form-group col-md-3">
      <span class="key">Estado</span>
      <span class="value">{{ nota.prestador.uf | uppercase }}</span>
    </div>
    <div class="form-group col-md-3">
      <span class="key">Cidade</span>
      <span class="value text-capitalize">{{ nota.prestador.municipioIbge.municipio | lowercase }}</span>
    </div>
  </div>
</fieldset>

<fieldset
  *ngIf="!(nota.nota.notaTipo === 2 || nota.nota.notaTipo === 3 || nota.nota.notaTipo === 8) && (nota.tomador && modalidade === 1)">
  <legend>Dados do Comprador de Serviço</legend>
  <div class="row">
    <div class="form-group col-md-3">
      <span class="key">CNPJ / CPF</span>
      <span class="value">{{ nota.tomador.documento | mask: 'CPF_CNPJ' }}</span>
    </div>
    <div class="form-group col-md-3">
      <span class="key">Inscrição Municipal</span>
      <span class="value">{{ nota.tomador.inscricaoMunicipal }}</span>
    </div>
    <div class="form-group col-md-3">
      <span class="key">Razão Social</span>
      <span class="value text-capitalize">{{ nota.tomador.razaoSocial | lowercase }}</span>
    </div>
    <div class="form-group col-md-3">
      <span class="key">E-Mail</span>
      <span class="value">{{ nota.tomador.email ? (nota.tomador.email | lowercase) : '-' }}</span>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-3">
      <span class="key">Endereço</span>
      <span
        class="value text-capitalize">{{ nota.tomador.endereco ? nota.tomador.endereco : nota.tomador.enderecoExterior }}</span>
    </div>
    <div class="form-group col-md-3">
      <span class="key">Número</span>
      <span class="value">{{ nota.tomador.numero | lowercase }}</span>
    </div>
    <div class="form-group col-md-3">
      <span class="key">Estado</span>
      <span class="value">{{ nota.tomador.uf | uppercase }}</span>
    </div>
    <div class="form-group col-md-3">
      <span class="key">Cidade</span>
      <span class="value text-capitalize">{{ nota.tomador.municipioIbge.municipio | lowercase }}</span>
    </div>
  </div>
</fieldset>

<fieldset *ngIf="nota">
  <legend>Dados do Serviço</legend>
  <div class="form-row">
    <div class="form-group col-md-3">
      <div class="material-textfield">
        <input disabled id="dataPrestacaoServico" name="dataPrestacaoServico" type="text"
          [value]="nota.nota.competencia">
        <label>Data da Prestação do Serviço</label>
      </div>
    </div>
    <div class="form-group col-md-9">
      <div class="material-textfield">
        <input disabled id="atividadeServico" name="atividadeServico" type="text"
          [value]="nota.item.atividade ? (nota.item.servico.codigo + ' / ' + nota.item.atividade.codigo + ' - ' + nota.item.atividade.descricao) : (nota.item.servico.codigo + ' / ' + nota.item.servico.descricao)">
        <label>Serviço / Atividade</label>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-3">
      <div class="material-textfield">
        <input disabled id="aliquota" name="aliquota" type="text" value="{{ nota.item.aliquota | number:'1.2-2' }}%">
        <label>Alíquota</label>
      </div>
    </div>
    <div class="form-group col-md-9">
      <div class="material-textfield">
        <input disabled id="naturezaOperacao" name="naturezaOperacao" type="text"
          [value]="nota.item.naturezaOperacaoDTO.descricao">
        <label>Natureza de Operação</label>
      </div>
    </div>
  </div>
</fieldset>

<fieldset *ngIf="nota">
  <legend>Discriminação do Serviço</legend>
  <div class="form-row">
    <div class="form-group col-md-12">
      <div class="material-textfield">
        <textarea disabled placeholder="Discriminação do Serviço" id="discriminacaoServico" name="discriminacaoServico"
          rows="3" [value]="nota.descritivo?.discrimininacao"></textarea>
        <label class="textarea">Discriminação do Serviço</label>
      </div>
    </div>
  </div>
</fieldset>

<fieldset *ngIf="nota">
  <legend>Local de Prestação</legend>
  <div class="form-row">
    <div class="form-group col-md-3">
      <div class="material-textfield">
        <input disabled placeholder="Estado" id="ufPrestacao" name="ufPrestacao" type="text"
          [value]="nota.item.ufPrestacao">
        <label>Estado</label>
      </div>
    </div>
    <div class="form-group col-md-3">
      <div class="material-textfield">
        <input class="text-capitalize" disabled placeholder="Cidade" id="municipio" name="municipio" type="text"
          [value]="nota.item.municipioPrestacaoDTO.municipio | lowercase">
        <label>Cidade</label>
      </div>
    </div>
  </div>
</fieldset>

<fieldset *ngIf="nota">
  <legend>Construção Civil</legend>
  <div class="form-row">
    <div class="form-group col-md-3">
      <div class="material-textfield">
        <input disabled placeholder="Código de Obra" id="codigoObra" name="codigoObra" type="text"
          [value]="nota.construcaoCivil?.codigoObra">
        <label>Código de Obra</label>
      </div>
    </div>
    <div class="form-group col-md-3">
      <div class="material-textfield">
        <input disabled placeholder="Código ART" id="codigoArt" name="codigoArt" type="text"
          [value]="nota.construcaoCivil?.art">
        <label>Código ART</label>
      </div>
    </div>
  </div>
</fieldset>

<fieldset *ngIf="nota">
  <legend>Valores</legend>
  <div class="form-row">
    <div class="form-group col-md-2 mr-auto">
      <div class="material-textfield">
        <input disabled placeholder="0,00" id="valorServico" name="valorServico" type="text"
          [value]="nota.item.valorServico | currency:'BRL'">
        <label>Valor do Serviço (R$)</label>
      </div>
    </div>
    <div class="form-group col-md-2 mr-auto">
      <div class="material-textfield">
        <input disabled placeholder="0,00" id="valorDeducoes" name="valorDeducoes" type="text"
          [value]="nota.item.valorDeducoes | currency:'BRL'">
        <label>Valor das Deduções (R$)</label>
      </div>
    </div>
    <div class="form-group col-md-2 mr-auto">
      <div class="material-textfield">
        <input disabled placeholder="0,00" id="descontoCondicionado" name="descontoCondicionado" type="text"
          [value]="nota.item.descontoCondicionado | currency:'BRL'">
        <label>Desc. Condicionado (R$)</label>
      </div>
    </div>
    <div class="form-group col-md-2 mr-auto">
      <div class="material-textfield">
        <input disabled placeholder="0,00" id="descontoIncondicionado" name="descontoIncondicionado" type="text"
          [value]="nota.item.descontoIncondicionado | currency:'BRL'">
        <label>Desc. Incondicionado (R$)</label>
      </div>
    </div>
    <div class="form-group col-md-2 mr-auto">
      <div class="material-textfield">
        <input disabled placeholder="0,00" id="outrasRetencoes" name="outrasRetencoes" type="text"
          [value]="nota.item.outrasRetencoes | currency:'BRL'">
        <label>Outras Retenções (R$)</label>
      </div>
    </div>
  </div>
</fieldset>

<fieldset *ngIf="nota">
  <legend>Tributos Federais</legend>
  <div class="form-row">
    <div class="form-group col-md-2 mr-auto">
      <div class="material-textfield">
        <input disabled placeholder="0,00" id="valorPis" name="valorPis" type="text"
          [value]="nota.item.valorPis | currency:'BRL'">
        <label>PIS</label>
      </div>
    </div>
    <div class="form-group col-md-2 mr-auto">
      <div class="material-textfield">
        <input disabled placeholder="0,00" id="valorCofins" name="valorCofins" type="text"
          [value]="nota.item.valorCofins | currency:'BRL'">
        <label>COFINS</label>
      </div>
    </div>
    <div class="form-group col-md-2 mr-auto">
      <div class="material-textfield">
        <input disabled placeholder="0,00" id="valorIr" name="valorIr" type="text"
          [value]="nota.item.valorIr | currency:'BRL'">
        <label>IR</label>
      </div>
    </div>
    <div class="form-group col-md-2 mr-auto">
      <div class="material-textfield">
        <input disabled placeholder="0,00" id="valorInss" name="valorInss" type="text"
          [value]="nota.item.valorInss | currency:'BRL'">
        <label>INSS</label>
      </div>
    </div>
    <div class="form-group col-md-2 mr-auto">
      <div class="material-textfield">
        <input disabled placeholder="0,00" id="valorCsll" name="valorCsll" type="text"
          [value]="nota.item.valorCsll | currency:'BRL'">
        <label>CSLL</label>
      </div>
    </div>
  </div>
</fieldset>

<fieldset *ngIf="nota">
  <legend>Detalhamento</legend>
  <div class="form-row">
    <div class="form-group col-md-3">
      <div class="material-textfield">
        <input disabled placeholder="Tributos" id="valorTotalTributos" name="valorTotalTributos" type="text"
          [value]="nota.item.valorTotalTributos | currency:'BRL'">
        <label>Valor aproximados dos Tributos</label>
      </div>
    </div>
    <div class="form-group col-md-3">
      <div class="material-textfield">
        <input disabled placeholder="Simples Nacional" id="simplesNacional" name="simplesNacional" type="text"
          [value]="nota.item.simplesNacional ? 'Sim' : 'Não'">
        <label>Simples Nacional</label>
      </div>
    </div>
    <div class="form-group col-md-3">
      <div class="material-textfield">
        <input disabled placeholder="Regime Especial de Tributação" id="regimeEspecialTributacao"
          name="regimeEspecialTributacao" type="text"
          [value]="nota.item.regimeEspecialTributacaoDTO ? nota.item.regimeEspecialTributacaoDTO.descricao : 'Nenhum'">
        <label>Regime Especial de Tributação</label>
      </div>
    </div>
    <div class="form-group col-md-3">
      <div class="material-textfield">
        <input disabled placeholder="Regime de ISSQN" id="regimeIss" name="regimeIss" type="text"
          [value]="nota.prestador.regimeIssDTO.descricao">
        <label>Regime de ISSQN</label>
      </div>
    </div>
  </div>
</fieldset>

<fieldset *ngIf="nota">
  <legend>Cálculos</legend>
  <div class="form-row">
    <div class="form-group col-md-3">
      <div class="material-textfield">
        <input disabled placeholder="0,00" id="valorLiquido" name="valorLiquido" type="text"
          [value]="nota.nota.valorLiquidoNota | currency:'BRL'">
        <label>Valor Líquido (R$)</label>
      </div>
    </div>
    <div class="form-group col-md-3">
      <div class="material-textfield">
        <input disabled placeholder="0,00" id="baseCalculo" name="baseCalculo" type="text"
          [value]="nota.item.baseCalculo | currency:'BRL'">
        <label>Base de Cálculo (R$)</label>
      </div>
    </div>
    <div class="form-group col-md-3">
      <div class="material-textfield">
        <input disabled placeholder="0,00" id="valorIss" name="valorIss" type="text"
          [value]="nota.nota.valorIss | currency:'BRL'">
        <label>Valor do ISSQN (R$)</label>
      </div>
    </div>
    <div class="form-group col-md-3">
      <div class="material-textfield">
        <input disabled placeholder="0,00" id="issRetido" name="issRetido" type="text"
          [value]="nota.item.issRetido ? 'Sim' : 'Não'">
        <label>ISSQN a Reter</label>
      </div>
    </div>
  </div>
</fieldset>