<header class="header">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-5 col-md-3 pl-0 pl-md-2">
        <a [routerLink]="['/']">
          <div aria-label="logo" class="logo" [inlineSVG]="'/assets/img/logo-giss.svg'"></div>
        </a>
      </div>
      <div class="col-2 col-md-4 my-3 my-md-0">
        <figure class="brasao">
          <img *ngIf="client.imagem" alt="Logo Prefeitura" class="img-fluid" [src]="client.imagem.imagemBase64">
          <figcaption>
            <span class="prefeitura" [tooltip]="client.nomePrefeitura">
              {{ client.nomePrefeitura }}
            </span>
            <span class="secretaria" [tooltip]="client.nomeSecretaria">
              {{ client.nomeSecretaria }}
            </span>
          </figcaption>
        </figure>
      </div>
      <div class="col-5 col-md-5">
        <div class="row">
          <div class="col-md-7 d-none d-md-block">
            <span class="texts">
              {{ date | date: 'E dd' }} de {{ date | date: 'MMMM' }} de {{ date | date: 'yyyy' }}
            </span>
            <span class="texts">
              Seu IP é {{ ip }}
            </span>
          </div>
          <div class="col-md-5 pr-0 pr-md-2 d-flex justify-content-end align-items-center">
            <div>
              <a  href="{{ gissAjudaV2 }}" target="_blank">
                <div aria-label="logo" class="logo" [inlineSVG]="'/assets/img/botao-ajuda.svg'"></div>
              </a>
            </div>
          </div>
          <!--div class="col-md-5 pr-0 pr-md-2 d-flex justify-content-end align-items-center">
            <button class="btn btn-secondary btn-login" *ngIf="!isAuthenticated"
              [routerLink]="['/login']" type="button">
              Entrar
            </button>
            <figure class="user rounded-circle" *ngIf="isAuthenticated" [popover]="popoverUsuario">
              <img class="rounded-circle user-img" src="../../../../assets/img/sem-foto.jpg" title="Usuário">
              <figcaption class="user-text">
                <span>Nome Usuário</span>
              </figcaption>
            </figure>
          </div-->
        </div>
      </div>
    </div>
  </div>
</header>

<ng-template #popoverUsuario>
  <button class="btn btn-link" (click)="logoff()" type="button">
    Sair
  </button>
</ng-template>
