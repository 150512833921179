<ng-template #modalNotification>
  <div class="modal-body">
    <div class="notifier">
      <div class="row">
        <div class="col-md-12 text-center">
          <div *ngIf="notification.type == 'success'" aria-label="sucesso" class="icon"
            [inlineSVG]="'/assets/icons/correct.svg'"></div>
            <div *ngIf="notification.type == 'alert'" aria-label="alerta" class="icon"
            [inlineSVG]="'/assets/icons/alert.svg'"></div>
          <div *ngIf="notification.type == 'error'" aria-label="erro" class="icon"
            [inlineSVG]="'/assets/icons/error.svg'"></div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3>{{ notification.title }}</h3>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <p>{{ notification.message }}</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 d-flex justify-content-end">
          <button class="btn btn-outline-primary" (click)="modalRef.hide()" type="button">
            Fechar <i class="i-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>