<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <hr>
        <div class="row">
          <div class="col-md-6">
            <span class="version">{{ versao }}</span>
          </div>
          <div class="col-md-6 text-right">
            <p>{{ year }} | Eicon - Todos os direitos reservados</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
