import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DialogConfirmService {

  private subject = new Subject<any>();

  constructor() { }

  confirm(title: string, message: string, confirm: () => void) {
    this.setConfirmation(title, message, confirm);
  }

  setConfirmation(title: string, message: string, confirm: () => void) {
    this.subject.next({
      title,
      message,
      confirm: (modalRef: any) => {
        modalRef.hide();
        confirm();
      }
    });
  }

  getDialogData(): Observable<any> {
    return this.subject.asObservable();
  }
}
