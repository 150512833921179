import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { BannerService } from './banner.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  @Input() titleColor: string;

  isAuthenticated: boolean;
  indicadores: any;

  frontGissV2 = environment.frontGissV2;

  constructor(
    private authService: AuthService,
    private bannerService: BannerService
  ) { }

  ngOnInit(): void {
    this.getAuth();
    this.getIndicadores();
  }

  private getAuth(): void {
    this.authService.authenticated.subscribe(data => this.isAuthenticated = data);
  }

  private getIndicadores(): void {
    this.bannerService.getIndicadores().subscribe(data => this.indicadores = data.conteudo);
  }

}
