import { Component, OnInit, Input, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { SortableColumnService } from './sortable-column.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'sortable-column',
  templateUrl: './sortable-column.component.html'
})
export class SortableColumnComponent implements OnInit, OnDestroy {

  constructor(
    private sortableColumnService: SortableColumnService
  ) { }

  // tslint:disable-next-line: no-input-rename
  @Input('sortable-column') columnName: string;
  // tslint:disable-next-line: no-input-rename
  @Input('sort-direction') sortDirection = '';

  private columnSortedSubscription: Subscription;

  @HostListener('click')
  sort() {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.sortableColumnService.columnSorted({ sortColumn: this.columnName, sortDirection: this.sortDirection });
  }

  ngOnInit() {
    this.columnSortedSubscription = this.sortableColumnService.columnSorted$.subscribe(event => {
      if (this.columnName !== event.sortColumn) {
        this.sortDirection = '';
      }
    });
  }

  ngOnDestroy() {
    this.columnSortedSubscription.unsubscribe();
  }

}
