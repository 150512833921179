export const environment = {
  production: true,
  base: location.origin,
  api: ' https://gissv2-2704302.eiconbrasil.com.br',
  appId: 'c7d920e2-7964-4f36-96ca-028eadb056c4',
  versao: 'v2.00.M.20210204.01',
  frontGissV2: 'https://maceio.giss.com.br/portal/#/login-portal',
  gissAjudaV2: 'https://maceio.giss.com.br/giss-ajuda/',
  redirectUrl: '/'
};
