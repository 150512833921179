import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AutocompleteEmpresaService {

  private api = environment.api;

  constructor(
    private http: HttpClient
  ) { }

  buscarEmpresa(idCliente: string, idEmpresa: string, param: string): Observable<any> {
    return this.http.get(
      `${this.api}/service-empresa/api/cliente-fornecedor/tomador/${idCliente}/${idEmpresa}/tipo/2/cliente?parametro=${param}`
    );
  }

  detalheEmpresa(idCliente: string, idEmpresa: string): Observable<any> {
    return this.http.get(`${this.api}/service-empresa/api/empresa/endereco/${idCliente}/${idEmpresa}`);
  }

}
