<router-outlet></router-outlet>

<ng-http-loader [backgroundColor]="color" spinner="sk-chasing-dots" [filteredUrlPatterns]="excludesUrls">
</ng-http-loader>

<app-notifier></app-notifier>
<app-dialog-confirm></app-dialog-confirm>
<app-dialog-ok></app-dialog-ok>
<div id="imagem-no-canto">
    <a href="https://www.cervelloesm.com.br/Eicon/ChatBot/Home?pk=k3JspT5s%2FUs%3D&amp;winopen=1" target="_blank" onclick="window.open(this.href, 'ChatBot', 'width=500,height=600'); return false;">
        <img src="assets/img/img_chat_bot.png" alt="Ajuda" style="cursor:pointer;">
    </a>
</div>