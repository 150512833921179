import { Component, OnInit, Input } from '@angular/core';
import { VisualizarNotaService } from './visualizar-nota.service';

@Component({
  selector: 'app-visualizar-nota',
  templateUrl: './visualizar-nota.component.html',
  styleUrls: ['./visualizar-nota.component.scss']
})
export class VisualizarNotaComponent implements OnInit {

  @Input() idNota: string;
  @Input() modalidade: number;
  nota: any;

  constructor(
    private visualizarNotaService: VisualizarNotaService
  ) { }

  ngOnInit(): void {
    this.getNota();
  }

  private getNota(): void {
    this.visualizarNotaService.buscarNota(this.idNota).subscribe(data => {
      this.nota = data.conteudo;
    });
  }

  public downloadPdf(nota: any): void {
    this.visualizarNotaService.downloadPdf(nota.id, nota.codigoVerificacao).subscribe(data => {
      this.downloadFile(data, nota.numero, 'pdf');
    });
  }

  public downloadXml(nota: any): void {
    this.visualizarNotaService.downloadXml(nota.id, nota.codigoVerificacao).subscribe(data => {
      this.downloadFile(data, nota.numero, 'xml');
    });
  }

  private downloadFile(data: any, nota: string, type: string): void {
    const link = document.createElement('a');
    const file = new Blob([data], { type: `application/${type}` });
    const fileurl = window.URL.createObjectURL(file);
    link.setAttribute('download', `${nota}.${type}`);
    link.setAttribute('href', fileurl);
    link.click();
    link.remove();
  }

}
