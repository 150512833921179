<div class="form-row">
  <div class="form-group col-md-6">
    <ng-select bindLabel="razaoSocial" (change)="pesquisar()" [items]="empresaItens"
      placeholder="Pesquisar por CPF/CNPJ, Insc Municipal ou Razão Social" [typeahead]="empresaTypeahead">
      <ng-template ng-header-tmp>
        <h4>Cadastro de Empresas</h4>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div (click)="selecionarEmpresa(item)">
          <p class="razaoSocial">{{ item.razaoSocial | lowercase }}</p>
          <p class="cnpj">{{ item.documento | mask: 'CPF_CNPJ' }}</p>
        </div>
      </ng-template>
      <ng-template ng-typetosearch-tmp>
        <div class="ng-option disabled">
          Pesquisar por CPF/CNPJ, Insc Municipal ou Razão Social
        </div>
      </ng-template>
      <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
        <div class="ng-option disabled">
          Nenhum empresa encontrada com o termo "{{searchTerm}}"
        </div>
      </ng-template>
      <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
        <div class="ng-option disabled">
          Buscando empresas com "{{searchTerm}}"
        </div>
      </ng-template>
    </ng-select>
    <button class="btn-invisible" #hiddenBtn></button>
  </div>
</div>
<div class="form-row" *ngIf="empresa">
  <div class="form-group col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <span class="key">CNPJ / CPF</span>
            <span class="value">{{ empresa.documento | mask: 'CPF_CNPJ' }}</span>
          </div>
          <div class="col-md-3">
            <span class="key">Inscrição Municipal</span>
            <span class="value">{{ empresa.inscricaoMunicipal }}</span>
          </div>
          <div class="col-md-3">
            <span class="key">Razão Social</span>
            <span class="value text-capitalize">{{ empresa.razaoSocial | lowercase }}</span>
          </div>
          <div class="col-md-3">
            <span class="key">E-Mail</span>
            <span class="value">{{ empresa.email | lowercase }}</span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-3">
            <span class="key">Endereço</span>
            <span class="value text-capitalize">{{ empresa.endereco | lowercase }}</span>
          </div>
          <div class="col-md-3">
            <span class="key">Número</span>
            <span class="value">{{ empresa.numero | lowercase }}</span>
          </div>
          <div class="col-md-3">
            <span class="key">Estado</span>
            <span class="value">{{ empresa.estado | uppercase }}</span>
          </div>
          <div class="col-md-3">
            <span class="key">Cidade</span>
            <span class="value text-capitalize">{{ empresa.cidade | lowercase }}</span>
          </div>
        </div>
        <button class="btn-delete" (click)="removerEmpresa()" type="button">X</button>
      </div>
    </div>
  </div>
</div>