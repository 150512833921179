import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FluxoAutenticacaoService } from '../fluxo-autenticacao.service';
import { AutenticarService } from './fluxo-autenticar.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { NotifierService } from 'src/app/core/components/app/notifier/notifier.service';
import { DialogConfirmService } from '../../../app/dialog-confirm/dialog-confirm.service';
import { LoginService } from 'src/app/core/services/empresa/login.service';


@Component({
  selector: 'app-fluxo-autenticar',
  templateUrl: './fluxo-autenticar.component.html',
  styleUrls: ['./fluxo-autenticar.component.scss']
})
export class FluxoAutenticarComponent implements OnInit {

  @Input() elementRef: any;

  dataSteps: any;
  form: FormGroup;
  usuario: any;
  exibirToken: boolean;

  private token: string;

  constructor(
    private fb: FormBuilder,
    private fluxoAutenticacaoService: FluxoAutenticacaoService,
    private autenticarService: AutenticarService,
    private authService: AuthService,
    private notifier: NotifierService,
    private dialogConfirm: DialogConfirmService,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.fluxoAutenticacaoService.changeStep.subscribe((step: number) => {
      if (step === 2) {
        this.buildForm();
      }
    });
  }

  private buildForm(): void {
    this.dataSteps = this.fluxoAutenticacaoService.getData();
    this.form = this.fb.group({
      cpf: [{ value: this.dataSteps.form.cpf, disabled: true }, [Validators.required]],
      senha: [null, [Validators.required]],
    });
  }

  public submitForm(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      if (this.exibirToken) {
        this.validarToken();
      } else {
        this.autenticar();
      }
    }
  }

  private autenticar(): void {
    this.authService.getToken(this.dataSteps.form.cpf, this.form.value.senha).subscribe(data => {
      this.token = data.access_token;
      const idUsuario = data.codigo_usuario;
      this.autenticarService.getUsuarioPorId(idUsuario, this.token).subscribe(resp => {
        this.usuario = resp.conteudo;
        this.verificarCelular();
      })
    }, () => {
      this.notifier.notify('error', 'Autenticação', 'Usuário ou senha inválidos.');
    });
  }

  private verificarCelular(): void {
    this.autenticarService.validarCelular(this.usuario.id).subscribe(data => {
      data.conteudo ? this.informarToken() : this.proximoPasso();
    });
  }

  private informarToken(): void {
    this.form.addControl('codigo', this.fb.control(null, [Validators.required]));
    this.form.get('senha').disable();
    this.enviarToken();
    this.exibirToken = true;
    this.notifier.notify('alert', 'Autenticação', 'O seu cadastro será validado após informar o código de validação enviado via SMS para o celular cadastrado.');
  }

  private enviarToken(): void {
    this.autenticarService.enviarSms(this.usuario.telefone).subscribe();
  }

  private validarToken(): void {
    const input = {
      token: this.form.value.codigo,
      telefone: this.usuario.telefone
    };
    this.autenticarService.validarSms(input).subscribe(() => {
      this.proximoPasso();
    });
  }

  private proximoPasso(): void {
    if (this.token) {
      this.authService.register(this.token);
      this.fluxoAutenticacaoService.setData('usuario', this.usuario);
      this.fluxoAutenticacaoService.emitChangeStep(3);
      this.elementRef.nextStep();
    }
  }

  public voltar(): void {
    this.form.removeControl('codigo');
    this.form.get('senha').enable();
    this.exibirToken = false;
    this.elementRef.previousStep();
  }

  public confirmarRecuperarSenha(): void {
    const email = this.dataSteps.usuario?.email?.email

    const title = `Confirmar a recuperação de senha`;
    const message = `A nova senha será enviada para o endereço de e-mail ${email}. Deseja continuar?`;
    this.dialogConfirm.confirm(title, message, () => {
      this.recuperarSenhaPorEmail(email);
    });

  }

  private recuperarSenhaPorEmail(email: string): void {
    this.loginService.recuperarSenha(email).subscribe(() => {
      this.notifier.notify(
          'success',
          'Senha recuperada',
          `Uma nova senha foi enviada para o endereço de e-mail ${email}`
        );
    }, resp => {
      if (resp.error?.mensagem) {
        this.notifier.notify( 'error', 'Erro', resp.error.mensagem );
      } else {
        this.notifier.notify( 'error', 'Erro', resp );
      }
    });
  }

}
