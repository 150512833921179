import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  storage = sessionStorage;

  constructor() { }

  getItem(key: string): string {
    const item = this.storage.getItem(key);
    return item ? atob(item) : null;
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(key, btoa(value));
  }

  removeItem(key: string) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }

}
