import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BuscarEmpresaService } from './buscar-empresa.service';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';

@Component({
  selector: 'app-buscar-empresa',
  templateUrl: './buscar-empresa.component.html',
  styleUrls: ['./buscar-empresa.component.scss']
})
export class BuscarEmpresaComponent implements OnInit {

  @Input() redirectUrl: string;
  public configuration: Config;
  public columns: Columns[];
  form: FormGroup;
  empresas: any;
  page = 1;

  constructor(
    private fb: FormBuilder,
    private buscarEmpresaService: BuscarEmpresaService
  ) { }

  ngOnInit(): void {
    this.tableConfig();
    this.buildForm();
  }

  private tableConfig(): void {
    this.configuration = { ...DefaultConfig };
    this.configuration.searchEnabled = true;
    this.configuration.tableLayout.style = 'tiny';
    this.configuration.tableLayout.striped = true;
    this.configuration.tableLayout.hover = false;
    this.configuration.rows = 5;
    this.columns = [
      { key: 'documento', title: 'CNPJ/CPF', width: '15%' },
      { key: 'razaoSocial', title: 'Razão Social/Nome', width: '45%' },
      { key: 'inscricaoMunicipal', title: 'Insc.Municipal', width: '15%' },
      { key: 'cadastro', title: 'Data de Cadastro', width: '15%' },
      { key: 'actions', title: '', width: '10%', orderEnabled: false, searchEnabled: false }
    ];
  }

  private buildForm(): void {
    this.form = this.fb.group({
      search: [''],
    });
  }

  public submitForm(): void {
    if (this.form.valid) {
      if (this.form.value.search !== '') {
        this.companySearch(this.form.value.search);
      }
    }
  }

  private companySearch(param: string): void {
    this.buscarEmpresaService.buscarEmpresas(param).subscribe(data => this.empresas = data.conteudo);
  }

}
