<form autocomplete="off" novalidate [formGroup]="form" (ngSubmit)="submitForm()" appScrollToFirstInvalid>
  <fieldset>
    <legend>Por favor indique seus dados pessoais</legend>
    <div class="form-row">
      <div class="form-group col-md-3">
        <div class="material-textfield">
          <input placeholder="000.000.000-00" (input)="changeCpf()" mask="000.000.000-00" formControlName="cpf" id="cpf"
            name="cpf" type="text">
          <label>CPF</label>
        </div>
        <app-form-validator [control]="form.controls.cpf"></app-form-validator>
      </div>
    </div>
    <div class="form-row" *ngIf="usuario && !form.controls.cpf.errors">
      <div class="form-group col-md-12">
        <p class="cpf-cadastrado">Identificamos que você já possui um cadastro</p>
      </div>
      <div class="form-group col-md-6">
        <div class="material-textfield">
          <input disabled id="nomeCadastrado" name="nomeCadastrado" type="text" [value]="usuario.nome">
          <label for="nomeCadastrado">Nome</label>
        </div>
      </div>
      <div class="form-group col-md-6">
        <div class="material-textfield">
          <input disabled id="emailCadastrado" name="emailCadastrado" type="text" [value]="usuario.email.email">
          <label for="emailCadastrado">E-Mail</label>
        </div>
      </div>
    </div>
    <section id="novo-usuario" *ngIf="cpfNaoLocalizado && !form.controls.cpf.errors">
      <div class="form-row">
        <div class="form-group col-md-6">
          <div class="material-textfield">
            <input placeholder="Nome" formControlName="nome" id="nome" name="nome" type="text">
            <label for="nome">Nome</label>
          </div>
          <app-form-validator [control]="form.controls.nome"></app-form-validator>
        </div>
        <div class="form-group col-md-6">
          <div class="material-textfield">
            <input placeholder="Nome" formControlName="nomeMae" id="nomeMae" name="nomeMae" type="text">
            <label for="nome">Nome da Mãe</label>
          </div>
          <app-form-validator [control]="form.controls.nomeMae"></app-form-validator>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3">
          <div class="material-textfield">
            <input placeholder="(00) 00000-00000" mask="(00) 00000-0000" formControlName="celular" id="celular"
              name="celular" type="text">
            <label for="celular">Celular</label>
          </div>
          <app-form-validator [control]="form.controls.celular"></app-form-validator>
        </div>
        <div class="form-group col-md-3">
          <div class="material-textfield">
            <input placeholder="00/00/0000" bsDatepicker formControlName="dataNascimento"
              [bsConfig]="{ showWeekNumbers:false, dateInputFormat: 'DD/MM/YYYY', useUtc: true }"
              id="dataNascimento" name="dataNascimento" type="text" [maxDate]="maxDateNascimento">
            <label for="dataNascimento">Data de Nascimento</label>
          </div>
          <app-form-validator [control]="form.controls.dataNascimento"></app-form-validator>
        </div>
        <div class="form-group col-md-3">
          <div class="material-textfield">
            <input placeholder="RG" formControlName="rg" id="rg" name="rg" type="text">
            <label for="rg">RG</label>
          </div>
          <app-form-validator [control]="form.controls.rg"></app-form-validator>
        </div>
        <div class="form-group col-md-3">
          <div class="material-textfield">
            <input placeholder="CRC" formControlName="crc" id="crc" name="crc" type="text">
            <label for="crc">CRC</label>
          </div>
          <app-form-validator [control]="form.controls.crc"></app-form-validator>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <div class="material-textfield">
            <input placeholder="E-Mail" formControlName="email" id="email" name="email" type="text">
            <label for="email">E-Mail</label>
          </div>
          <app-form-validator [control]="form.controls.email"></app-form-validator>
        </div>
        <div class="form-group col-md-6">
          <div class="material-textfield">
            <input placeholder="Confirmar E-Mail" formControlName="confirmarEmail" id="confirmarEmail"
              name="confirmarEmail" type="text" onPaste="return false" onDrop="return false">
            <label for="email">Confirmar E-Mail</label>
          </div>
          <app-form-validator [control]="form.controls.confirmarEmail"></app-form-validator>
        </div>
      </div>
    </section>
  </fieldset>
  <div class="form-row">
    <div class="form-group col-md-12 d-flex justify-content-end">
      <button class="btn btn-outline-primary mr-2" [routerLink]="['..']" type="button">
        Voltar
      </button>
      <button class="btn btn-primary" type="submit">
        Próximo
      </button>
    </div>
  </div>
</form>
