<form autocomplete="off" novalidate [formGroup]="form" (ngSubmit)="submitForm()" appScrollToFirstInvalid>
  <fieldset>
    <legend>Buscar Empresa</legend>
    <div class="form-row">
      <div class="form-group col-md-6">
        <div class="material-textfield">
          <input placeholder="Pesquisar por CNPJ/CPF, Insc.Municipal ou Razão Social/Nome" formControlName="search"
            id="search" name="search" type="text">
          <label></label>
          <div aria-label="buscar" class="input-icon" (click)="submitForm()" [inlineSVG]="'/assets/icons/search.svg'">
          </div>
        </div>
      </div>
      <div class="form-group col-md-6 d-flex align-items-center justify-content-end" *ngIf="empresas">
        <span class="informative">
          * Os dados visualizados do cadastro da empresa são do momento exato desta consulta
        </span>
      </div>
    </div>
    <div class="row" *ngIf="empresas">
      <div class="col-md-12">
        <ngx-table [configuration]="configuration" [data]="empresas" #table [columns]="columns">
          <ng-template let-row let-index="index">
            <td data-title="CNPJ/CPF">{{ row.documento | mask: 'CPF_CNPJ' }}</td>
            <td data-title="Razão Social/Nome">{{ row.razaoSocial }}</td>
            <td data-title="Insc.Municipal">{{ row.inscricaoMunicipal }}</td>
            <td data-title="Data de Cadastro">{{ row.cadastro | date: 'dd/MM/yyyy' }}</td>
            <td data-title="Ações" class="text-left text-md-center" >
              <button class="btn btn-sm btn-light" [routerLink]="[redirectUrl, row.idEmpresa]" tooltip="Visualizar"
                [delay]="300" type="button">
                <div aria-label="buscar" class="table-icon" [inlineSVG]="'/assets/icons/search.svg'">
                </div>
              </button>
            </td>
          </ng-template>
        </ngx-table>
      </div>
    </div>
  </fieldset>
</form>