import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { DialogConfirmService } from './dialog-confirm.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {

  @ViewChild('modalDialog', { static: false }) modalDialog: ElementRef;
  modalRef: BsModalRef;
  dialog: any;

  constructor(
    private modalService: BsModalService,
    private dialogConfirmService: DialogConfirmService,
  ) { }

  ngOnInit(): void {
    this.observableOpenDialog();
  }

  observableOpenDialog(): void {
    this.dialogConfirmService.getDialogData().subscribe(dialog => {
      this.dialog = dialog;
      this.modalRef = this.modalService.show(
        this.modalDialog, {
        keyboard: false, backdrop: 'static', class: 'modal-dialog-centered'
      });
    });
  }

}
