// angular
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// layouts
import { PortalEmpresaCidadaoComponent } from './core/layouts/portal-empresa-cidadao/portal-empresa-cidadao.component';
import { PortalServidorComponent } from './core/layouts/portal-servidor/portal-servidor.component';

const routes: Routes = [
  {
    path: '', component: PortalEmpresaCidadaoComponent,
    children: [
      { path: '', loadChildren: () => import('./pages/empresa-cidadao/empresa-cidadao.module').then(m => m.EmpresaCidadaoModule) }
    ]
  },
  {
    path: '', component: PortalServidorComponent,
    children: [
      { path: 'praca-atendimento', loadChildren: () => import('./pages/servidor/servidor.module').then(m => m.ServidorModule) }
    ]
  },
  // { path: '**', redirectTo: 'empresa-cidadao' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
