import { Injectable } from '@angular/core';
import themeJson from './theme.json';

@Injectable({
  providedIn: 'root'
})

export class ThemeService {

  private theme = 'servidor';

  public setTheme(name: string) {
    this.theme = name;
    const theme = this.getDataTheme(name);
    this.setThemeColors(theme.colors);
  }

  public getTheme(): string {
    return this.theme;
  }

  private getDataTheme(name: string) {
    return themeJson[name];
  }

  private setThemeColors(theme: any) {
    Object.keys(theme).forEach(k =>
      document.documentElement.style.setProperty(`--${k}`, theme[k])
    );
  }

}
