import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { distinctUntilChanged, filter, debounceTime, switchMap } from 'rxjs/operators';
import { AutocompleteEmpresaService } from './autocomplete-empresa.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { StorageService } from 'src/app/core/utils/storage.service';

@Component({
  selector: 'app-autocomplete-empresa',
  templateUrl: './autocomplete-empresa.component.html',
  styleUrls: ['./autocomplete-empresa.component.scss']
})
export class AutocompleteEmpresaComponent implements OnInit {

  @Output() empresaSelecionada = new EventEmitter();

  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @ViewChild('hiddenBtn', { static: false }) hiddenBtn: ElementRef;

  empresaTypeahead = new EventEmitter<string>();
  empresaItens: any;

  idCliente = this.storage.getItem('idCliente');
  idEmpresa = this.storage.getItem('idEmpresa');
  empresas: any[];
  empresa: any;

  constructor(
    private autocompleteEmpresaService: AutocompleteEmpresaService,
    private storage: StorageService
  ) { }

  ngOnInit(): void {
    this.pesquisar();
  }

  public pesquisar(): void {
    this.empresaTypeahead.pipe(
      distinctUntilChanged(),
      filter(res => res.length > 2),
      debounceTime(400),
      switchMap(term => this.pesquisarEmpresas(term))
    ).subscribe(
      (result) => this.empresaItens = result,
      (err) => this.empresaItens = []
    );
  }

  private pesquisarEmpresas(text: string): any {
    this.autocompleteEmpresaService.buscarEmpresa(this.idCliente, this.idEmpresa, text).subscribe(data => {
      this.empresas = data.conteudo;
      this.empresaItens = this.empresas;
      return this.empresas;
    });
  }

  public selecionarEmpresa(empresa: any): void {
    this.autocompleteEmpresaService.detalheEmpresa(this.idCliente, empresa.id).subscribe(data => {
      const cliente = data.conteudo;
      this.empresa = {
        idEmpresa: cliente.idEmpresa,
        tipoTomador: empresa.tipoTomador,
        documento: cliente.documento,
        inscricaoMunicipal: cliente.inscricaoMunicipal,
        razaoSocial: cliente.razaoSocial,
        email: cliente.email?.email,
        endereco: cliente.endereco?.logradouro,
        numero: cliente.endereco?.numero,
        estado: cliente.endereco?.estado,
        cidade: cliente.endereco?.cidade
      };
      this.ngSelectComponent.handleClearClick();
      this.hiddenBtn.nativeElement.focus();
      this.empresaSelecionada.emit(this.empresa);
    });
  }

  public removerEmpresa(): void {
    this.empresa = null;
    this.empresaSelecionada.emit(this.empresa);
  }

}
