import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class NotifierService {

  notifier = new EventEmitter<any>();

  notify(type: 'success' | 'alert' | 'error', title: string, message: string) {
    this.notifier.emit({ type, title, message });
  }

}
