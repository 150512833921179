import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  private clienteBehaviorSubject = new BehaviorSubject<boolean>(false);
  temCliente = this.clienteBehaviorSubject.asObservable();

  constructor() { }

  public carregouCliente(status: boolean) {
    this.clienteBehaviorSubject.next(status);
  }

}
