<ng-template #modalDialog>
  <div class="modal-body">
    <div class="dialog">
      <div class="row">
        <div class="col-md-12">
          <h3 class="d-flex align-items-center">
            <div aria-label="success" class="icon question" [inlineSVG]="'/assets/icons/question.svg'">
            </div>
            <span class="ml-3">{{ dialog.title }}</span>
          </h3>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <p>{{ dialog.message }}</p>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12 d-flex justify-content-end">
          <button class="btn btn-outline-primary" (click)="modalRef.hide()" type="button">
            Não
          </button>
          <button class="btn btn-primary ml-2" (click)="dialog.confirm(modalRef)" type="button">
            Sim
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>