<form *ngIf="form" autocomplete="off" novalidate [formGroup]="form" (ngSubmit)="submitForm()" appScrollToFirstInvalid>
  <fieldset>
    <legend>Autenticar</legend>
    <p class="autenticar">
      Para seguir para o próximo passo informe suas credenciais
    </p>
    <div class="form-row">
      <div class="form-group col-md-3">
        <div class="material-textfield">
          <input placeholder="000.000.000-00" mask="000.000.000-00" formControlName="cpf" id="cpf" name="cpf"
            type="text">
          <label>CPF</label>
        </div>
        <app-form-validator [control]="form.controls.cpf"></app-form-validator>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-3">
        <div class="material-textfield">
          <input placeholder="********" formControlName="senha" id="senha" name="senha" type="password">
          <label>Senha</label>
        </div>
        <app-form-validator [control]="form.controls.senha"></app-form-validator>
      </div>
      <div class="form-group col-md-3 align-self-end">
        <a [routerLink]="" (click)="confirmarRecuperarSenha()">Esqueceu a senha?</a>
      </div>
    </div>
  </fieldset>
  <fieldset *ngIf="exibirToken">
    <legend>Validar Celular</legend>
    <p class="autenticar">
      Insira os números enviados para o seu celular
    </p>
    <div class="form-row">
      <div class="form-group col-md-3">
        <div class="material-textfield">
          <input placeholder="000000" mask="000000" formControlName="codigo" id="codigo" name="codigo" type="text">
          <label>Código de Validação</label>
        </div>
        <app-form-validator [control]="form.controls.codigo"></app-form-validator>
      </div>
    </div>
  </fieldset>
  <div class="form-row mt-3">
    <div class="form-group col-md-12 d-flex justify-content-center justify-content-md-end">
      <button class="btn btn-outline-primary mr-2" (click)="voltar()" type="button">
        Anterior
      </button>
      <button class="btn btn-primary" type="submit">
        Próximo
      </button>
    </div>
  </div>
</form>
