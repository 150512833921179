import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/core/utils/storage.service';

@Injectable({
  providedIn: 'root'
})

export class VisualizarNotaService {

  private api = environment.api;

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  buscarNota(idNota: string): Observable<any> {
    const idCliente = this.storage.getItem('idCliente');
    return this.http.get(`${this.api}/service-declaracao/api/nota/${idCliente}/${idNota}`);
  }

  downloadPdf(idNota: string, codigoVerificacao: string): Observable<any> {
    const idCliente = this.storage.getItem('idCliente');
    return this.http.get(
      `${this.api}/service-relatorio/api/relatorio/nota-autenticacao/pdf/${idCliente}/${idNota}/codigo-verificacao/${codigoVerificacao}`,
      { responseType: 'arraybuffer' }
    );
  }

  downloadXml(idNota: string, codigoVerificacao: string): Observable<any> {
    const idCliente = this.storage.getItem('idCliente');
    return this.http.get(
      `${this.api}/service-relatorio/api/relatorio/nota-autenticacao/xml/${idCliente}/${idNota}/codigo-verificacao/${codigoVerificacao}`,
      { responseType: 'arraybuffer' }
    );
  }

}

