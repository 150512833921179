import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-wizard-step',
  templateUrl: './wizard-step.component.html'
})
export class WizardStepComponent {

  @Input() number: number;
  @Input() title: string;
  @Input() active: boolean;
  @Input() completed: boolean;
  @Input() hiddenStep: boolean;
  @Output() stepFinish = new EventEmitter();

}
