<div class="modal-body">
  <div class="row">
    <div class="col-9">
      <h4>{{ title }}</h4>
    </div>
    <div class="col-3 d-flex justify-content-end">
      <div aria-label="Close" class="close-icon" (click)="modalRef.hide()" [inlineSVG]="'/assets/icons/close.svg'" [tooltip]="'Fechar'">
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>