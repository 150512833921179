import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private api = environment.api;

  constructor(private http: HttpClient) { }

  public adicionarUsuarioCpom(obj: any): Observable<any> {
    let objjson =  Object.assign({}, obj);
    delete objjson.dadosUpload;

    const formData = new FormData();
    formData.append('model', JSON.stringify(objjson));
    obj.dadosUpload[0].arquivos.forEach(f => formData.append('file', f));

    return this.http.post(`${this.api}/service-empresa/api/login/usuario/cpom`, formData);
  }

  public recuperarSenha(email: string): Observable<any> {
    const body = {
      email,
      app: environment.appId
    }

    return this.http.post(`${this.api}/service-empresa/api/login/recuperar-senha`, body);
  }
}
