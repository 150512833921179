import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class HeaderService {

  private api = environment.api;

  constructor(
    private http: HttpClient
  ) { }

  getIdCliente(subdomain: string): Observable<any> {
    return this.http.get(`${this.api}/service-cliente/api/cliente/subdominio/${subdomain}`);
  }

  getCliente(idCliente: string): Observable<any> {
    return this.http.get(`${this.api}/service-cliente/api/cliente/imagem/${idCliente}`);
  }

  getIPAddress(): Observable<any> {
    return this.http.get('http://api.ipify.org/?format=json');
  }

}
